<style scoped lang="less">
  .block {
    & + .block {
      margin-top: 20px;
    }
  }
</style>

<template>
  <div class="resume.details.all">
    <RBase class="block" />
    <RDj class="block" />
    <RFamily class="block" />
  </div>
</template>

<script>
import RBase from './base'
import RDj from './dj'
import RFamily from './family'

export default {
  components: {
    RBase, RDj, RFamily
  }
}
</script>
