<style scoped lang="less">
  .content {
    padding: 20px;
    box-sizing: border-box;
    display: flex;
  }
  .text-field, .text-value {
    line-height: 3;
    white-space: wrap;
  }
  .row {
    white-space: wrap;
    word-break: keep-all;
  }
</style>

<template>
  <r-card hborder>
    <span slot="title">家庭信息</span>
    <div style="padding: 20px;">
      <fm-table-new
      :full="true"
      border="row"
      size="large"
      :stripe="false"
      emptyPlaceholder="-"
      :columns="columns"
      :data-list="family"
    ></fm-table-new>
    </div>
    <!-- <table class="f-t">
      <tr class="f-t-t">
        <td>称谓</td>
        <td>姓名</td>
        <td>年龄</td>
        <td>政治面貌</td>
        <td>工作单位及职务</td>
      </tr>
      <tr v-for="(item, index) in family" :key="index" class="f-t-d">
        <td>
          {{item.cw}}
        </td>
        <td>
          {{item.name}}
        </td>
        <td>
          {{item.age}}
        </td>
        <td>
          {{item.zzmm}}
        </td>
        <td>
          {{item.gzdwjzc}}
        </td>
      </tr>
    </table> -->
  </r-card>
</template>

<script>
import RCard from '../components/card'
import { getWorkerInfo } from '../libs'
import { getAge } from '@/syslib/tools'
import { loadImgs } from '@/components/base/file/libv2'

export default {
  components: { RCard },
  data () {
    return {
      data: {},
      idCardSources: null,
      family: []
    }
  },
  computed: {
    columns () {
      return [{
        title: '称谓',
        field: 'cw'
      },
      {
        title: '姓名',
        field: 'name'
      },
      {
        title: '年龄',
        field: 'age'
      },
      {
        title: '政治面貌',
        field: 'zzmm'
      },
      {
        title: '工作单位及职务',
        field: 'gzdwjzc'
      }]
    },
    id () {
      return this.$route.query.id
    },
    age () {
      return getAge(this.data.birth, new Date())
    }
  },
  watch: {
    id () {
      this.loadData()
    }
  },
  methods: {
    loadData () {
      getWorkerInfo(this.id).then(async data => {
        this.data = data
        this.family = data && data.otherInfo && data.otherInfo.family ? data.otherInfo.family : [{}, {}, {}, {} , {}, {}]
        const items = []
        if (data.idCardFrontFileId) {
          items.push({
            name: '正面',
            fileIds: data.idCardFrontFileId
          })
        }
        if (data.idCardBackFileId) {
          items.push({
            name: '背面',
            fileIds: data.idCardBackFileId
          })
        }
        this.idCardSources = await loadImgs(items, (item) => item.name || '身份证')
      })
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
